/* 랜딩 컨텐츠 - 데스크탑 화면
-------------------------------------------------- */

.content-head-text {
  font-size: 2.37rem;
  margin-bottom: 3.75rem;
  font-weight: 700;
  margin-top: 6.3rem;
  text-align: center;
  line-height: 3rem;
}

/* 랜딩 컨텐츠 - 테블릿 이하 화면
-------------------------------------------------- */

@media (max-width: 768px) {
  .content-head-text {
    font-size: 1.5rem;
    line-height: inherit;
  }
}

/* 랜딩 슬라이드 - 데스크탑 화면
-------------------------------------------------- */

.landing-slide {
  color: white;
  padding: 8rem 0 8rem 5%;
}

.landing-slide-1 {
  background: url("../../public/images/landing/landing-slide-1.png") no-repeat center;
  background-size: cover;
}

.landing-slide-2 {
  background: url("../../public/images/landing/landing-slide-2.png") no-repeat center;
  background-size: cover;
}

.landing-slide-title {
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.44px;
  margin-bottom: 16px;
}

.landing-slide-content {
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.88px;
  margin-bottom: 24px;
}

.landing-slide-pagination-bullet {
  background: white;
  opacity: 100;
}

.landing-slide-pagination-bullet-active {
  background: #0bb25f;
  opacity: 100;
}

/* 랜딩 슬라이드 - 1650 이상 화면
-------------------------------------------------- */

@media (min-width: 1650px) {
  .landing-slide {
    min-height: 600px;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
}

/* 랜딩 슬라이드 - 태블릿 이하 화면
-------------------------------------------------- */

@media (max-width: 768px) {
  .landing-slide {
    display: flex;
    flex-direction: column;
    padding: 9rem 0 9rem 1rem;
    align-items: start;
  }

  .landing-slide-1 {
    background: url("../../public/images/landing/landing-slide-mobile-1.png") no-repeat center;
    background-size: cover;
  }

  .landing-slide-2 {
    background: url("../../public/images/landing/landing-slide-mobile-2.png") no-repeat center;
    background-size: cover;
  }

  .landing-slide-title {
    font-size: 17px;
  }

  .landing-slide-content {
    font-size: 32px;
  }

  .landing-slide-btn {
    height: 48px;
    font-size: 17px;
    width: auto;
  }

  .common-btn {
    padding: 8px 12px;
    height: 40px;
    font-size: 15px;
    font-weight: 500;
  }
}
